import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import submitStandardForm from '/src/functions/forms/submitStandardForm'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const CheckBox = loadable(() => import('/src/components/Form/CheckBox'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FieldSet = loadable(() => import('/src/components/Form/FieldSet'))
const FieldSpacer = loadable(() => import('/src/components/Form/FieldSpacer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const FormSection = loadable(() => import('/src/components/Form/FormSection'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const validationSchema = Yup.object({
    firstname: Yup.string().required('First name is required'),
    lastname: Yup.string().required('Last name is required'),
    phone: Yup.string().max(15, 'Cannot be longer than 15 digits').required('Phone number is required'),
    friend: Yup.object({
        firstname: Yup.string().required('First name is required'),
        lastname: Yup.string().required('Last name is required'),
        phone: Yup.string().max(15, 'Cannot be longer than 15 digits').required('Phone number is required'),
        email: Yup.string().email('Email address is not valid').required('Email is required'),
        role: Yup.string()
    }),
    consent: Yup.boolean().oneOf([true], 'You must confirm permission before this form can be submitted')
})

const CareersReferForm = ({ disclaimer }) => {
    const [isSubmitted, setSubmitted] = useState(false)

    return (
        <Formik
            initialValues={{
                firstname: '',
                lastname: '',
                phone: '',
                friend: {
                    firstname: '',
                    lastname: '',
                    phone: '',
                    email: '',
                    role: ''
                },
                consent: false
            }}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    firstname: values.firstname,
                    lastname: values.lastname,
                    phone: values.phone,
                    friend: {
                        firstname: values.friend.firstname,
                        lastname: values.friend.lastname,
                        phone: values.friend.phone,
                        email: values.friend.email,
                        role: values.friend.role
                    },
                    consent: values.consent
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'careers-raf-refer-form',
                    firebaseData: firebaseData,
                    confirmationEmail: process.env.GATSBY_RECRUITMENT_EMAIL_ADDRESS,
                    emailTemplate: 'careers-raf-refer-form-template'
                }

                setSubmitted(true)

                await submitStandardForm(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <RequiredText />

                    <FormSection title={'Your details'}>
                        <FormRow>
                            <FieldSet>
                                <FieldSpacer grow={'half'}>
                                    <TextField
                                        label={'Your first name'}
                                        name={'firstname'}
                                        placeholder={'e.g. Emma'}
                                        isSubmitting={isSubmitting}
                                        isSubmitted={isSubmitted}
                                        required
                                    />
                                </FieldSpacer>

                                <FieldSpacer grow={'half'}>
                                    <TextField
                                        label={'Your last name'}
                                        name={'lastname'}
                                        placeholder={'e.g. Jones'}
                                        isSubmitting={isSubmitting}
                                        isSubmitted={isSubmitted}
                                        required
                                    />
                                </FieldSpacer>
                            </FieldSet>
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={'Your phone number'}
                                name={'phone'}
                                format={'number'}
                                placeholder={'e.g. 07700900796'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                required
                            />
                        </FormRow>
                    </FormSection>

                    <FormSection title={'Details of friend you are referring'}>
                        <FormRow>
                            <FieldSet>
                                <FieldSpacer grow={'half'}>
                                    <TextField
                                        label={`Friend's first name`}
                                        name={'friend.firstname'}
                                        placeholder={'e.g. Emma'}
                                        isSubmitting={isSubmitting}
                                        isSubmitted={isSubmitted}
                                        required
                                    />
                                </FieldSpacer>

                                <FieldSpacer grow={'half'}>
                                    <TextField
                                        label={`Friend's last name`}
                                        name={'friend.lastname'}
                                        placeholder={'e.g. Jones'}
                                        isSubmitting={isSubmitting}
                                        isSubmitted={isSubmitted}
                                        required
                                    />
                                </FieldSpacer>
                            </FieldSet>
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={`Friend's phone number`}
                                name={'friend.phone'}
                                format={'number'}
                                placeholder={'e.g. 07700900796'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                required
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={`Friend's email address`}
                                name={'friend.email'}
                                type={'email'}
                                placeholder={'e.g. name@email.com'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                required
                            />
                        </FormRow>

                        <FormRow>
                            <TextField
                                label={'Role your friend is interested in'}
                                description={`Unsure? No problem, we'll chat with them!`}
                                name={'friend.role'}
                                placeholder={'e.g. Sales Executive'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                            />
                        </FormRow>
                    </FormSection>

                    <FormSection>
                        <FormRow>
                            <CheckBox
                                label={`I confirm I have permission to share the candidate's contact details with Protect Line for the purpose of discussing career opportunities`}
                                name={'consent'}
                                isSubmitting={isSubmitting}
                                isSubmitted={isSubmitted}
                                required
                            />
                        </FormRow>
                    </FormSection>

                    {disclaimer && <Alert content={disclaimer} />}

                    <CtaContainer align={'right'}>
                        <SubmitButton
                            initialText={'Submit'}
                            sentText={'Thank you!'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            disabled={!(isValid && dirty)}
                        />
                    </CtaContainer>

                    {isSubmitted === true && (
                        <Alert
                            variant={'info'}
                            content={`<p>Thank you. The recruitment team aims to contact your referral within <strong>48 hours</strong>.</p>`}
                        />
                    )}
                </Form>
            )}
        </Formik>
    )
}

CareersReferForm.propTypes = {
    /**
     * Disclaimer
     */
    disclaimer: PropTypes.string
}

CareersReferForm.defaultProps = {
    disclaimer: '<p>This is the disclaimer message</p>'
}

export default CareersReferForm
